import axiosInstance from "./axiosInstace";

function saveTokens(token, refreshToken, expiresInSeconds) {
    const expirationTime = new Date().getTime() + expiresInSeconds * 1000;
    const tokenData = {
        token,
        expirationTime,
    };
    localStorage.setItem('authToken', JSON.stringify(tokenData));
    localStorage.setItem('refreshToken', refreshToken);
}

function getToken() {
    const tokenData = localStorage.getItem('authToken');
    if (!tokenData) return null;

    const { token, expirationTime } = JSON.parse(tokenData);
    if (new Date().getTime() > expirationTime) {
        localStorage.removeItem('authToken');
        return null;
    }
    return token;
}

function timeToRefresh() {
    const tokenData = localStorage.getItem('authToken');
    if (!tokenData) return true;

    const { _, expirationTime } = JSON.parse(tokenData);
    return expirationTime - new Date().getTime() < 5 * 60 * 1000;
}

function removeToken() {
    localStorage.removeItem('authToken');
    localStorage.removeItem('refreshToken');
}

async function refreshToken() {
    if (!timeToRefresh()) return null;

    const token = localStorage.getItem('refreshToken');
    if (!token) return null;

    try {
        const response = await axiosInstance.get('/api/tokens', {
            headers: {
                Authorization: `Bearer ${token}`,
            },
            validateStatus: (status) => {
                return status === 200;
            },
        });

        const { accessToken, refreshToken } = response.data;
        saveTokens(accessToken, refreshToken, 10);
        return accessToken;
    } catch (error) {
        console.error('Failed to refresh token:', error);
        removeToken();
        return null;
    }
}

export { refreshToken, getToken, saveTokens }