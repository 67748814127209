import axios from 'axios';

const axiosInstance = axios.create({
    baseURL: 'https://api.flinzy.ai',
    timeout: 5000,
    headers: {
        'Content-Type': 'application/json',
    },
    validateStatus: (status) => {
        return status >= 200 && status < 500;
    },
});

export default axiosInstance;


