import CookieConsent from "react-cookie-consent";

const Cookies = () => (
  <CookieConsent
    location="bottom"
    buttonText="Got it!"
    style={{ background: "#496BFB", padding:"1.1em 0.5em 0.5em 0em" }}
    buttonStyle={{ color: "black", fonSize: "13px",backgroundColor:"#fff", minWidth:"140px",borderRadius:"5px",padding:".7em",fontWeight:"bold"}}
    expires={150}
  >
    This website uses cookies to ensure you get the best experience on our website.
    <span> <a style={{color:"#fff", textDecoration:"underline"}} href="https://www.freeprivacypolicy.com/live/7664cb86-9c00-4a96-9e76-7d6425f1145f" target="_blank">Learn more</a></span>
  </CookieConsent>
);
export default Cookies;