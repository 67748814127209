import React, { useEffect, useLayoutEffect, useState } from "react";
import {
  Box,
  TextField,
  Button,
  Typography,
  Paper,
  Link,
  createTheme,
  ThemeProvider,
  Divider,
  Grid,
} from "@mui/material";
import { FaGoogle, FaApple, FaMicrosoft } from "react-icons/fa";
import axiosInstance from "../../axiosInstace";
import { saveTokens, getToken } from "../../tokens";
import { useNavigate } from "react-router-dom";
import { GoogleLogin } from "@react-oauth/google";
import { GoogleOAuthProvider } from "@react-oauth/google";
import { useTranslation } from 'react-i18next'

const theme = createTheme({
  palette: {
    primary: {
      main: "#B753F9",
    },
    secondary: {
      main: "#B753F9",
    },
  },
});

const Registration = () => {
  const { t, ready } = useTranslation();
  const navigate = useNavigate();

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [repeatPassword, setRepeatPassword] = useState("");

  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const [repeatPasswordError, setRepeatPasswordError] = useState('');

  useEffect(() => {
    const token = getToken();
    if (token && token !== null) {
      navigate("/");
    }
  }, []);

  useEffect(() => {
    if (password !== repeatPassword) {
      setRepeatPasswordError('Пароли должны совпадать');
    } else {
      setRepeatPasswordError('');
    }
  }, [password, repeatPassword]);

  useEffect(() => {
    window.umami.track("Email input in registration");
  }, [email])

  useEffect(() => {
    window.umami.track("Password input in registration");
  }, [password]);

  useEffect(() => {
    window.umami.track("Repeat password input in registration");
  }, [repeatPassword]);

  const handleRegister = async () => {
    const response = await axiosInstance.post("/api/registration", {
      email,
      password,
    });

    if (response.status === 200) {
      window.umami.track("Success email and password registration");
      setError(false);
      setErrorMessage("");

      const accessToken = response.data.accessToken;
      const refreshToken = response.data.refreshToken;
      console.log("tokens:", JSON.stringify({ accessToken, refreshToken }));
      saveTokens(accessToken, refreshToken, 20 * 60);

      navigate("/");
    }

    if (response.status === 409) {
      window.umami.track("User with email already exists");
      setError(true);
      setErrorMessage("Пользователь с таким email уже существует");
    }
  };


  const handleSuccess = async (credentialResponse) => {
    window.umami.track("Success google login in registration");
    const token = credentialResponse.credential;
    const body = {
      token: token
    }
    const response = await axiosInstance.post("/api/login/google", body);
    window.umami.track("Success google login in registration2");
    saveTokens(response.data.accessToken, response.data.refreshToken, 20 * 60);
    navigate("/");
  };
  const handleError = () => {
    window.umami.track("Error google login in registration");
  };

  return (
    <ThemeProvider theme={theme}>
      <Box
        display="flex"
        flexDirection="column"
        minHeight="100vh"
        bgcolor="#f5f5f5"
        sx={{
          backgroundImage: 'url("./background.svg")',
          backgroundSize: "cover",
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
        }}
      >
        <Box flexGrow={1} display="flex" justifyContent="center" alignItems="center">
          <Paper elevation={3} sx={{ p: 4, width: 400 }}>
            <Typography variant="h5" align="center" gutterBottom>
            {t('signUp:title')}
            </Typography>
            <Box component="form" noValidate autoComplete="off" sx={{ mt: 2 }}>
              <TextField
                label={t('signUp:inputs:email')}
                variant="outlined"
                fullWidth
                margin="normal"
                value={email}
                onChange={(e) => { setError(false); setErrorMessage(''); setEmail(e.target.value); }}
                error={error}
              />
              <TextField
                label={t('signUp:inputs:password')}
                type="password"
                variant="outlined"
                fullWidth
                margin="normal"
                helperText={repeatPasswordError}
                error={repeatPasswordError !== ''}
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
              <TextField
                label={t('signUp:inputs:repeatPassword')}
                type="password"
                variant="outlined"
                fullWidth
                margin="normal"
                helperText={repeatPasswordError}
                error={repeatPasswordError !== ''}
                value={repeatPassword}
                onChange={(e) => setRepeatPassword(e.target.value)}
              />
              <Button
                variant="contained"
                color="primary"
                fullWidth
                sx={{ mt: 2 }}
                onClick={handleRegister}
                disabled={repeatPasswordError !== '' || error}
              >
                {t('signUp:title')}
              </Button>

              <Typography sx={{ mt: 2, mb: 2 }} variant="body1" align="center" color="rgb(237, 73, 86)" >
                {errorMessage}
              </Typography>

              <Typography margin="normal" variant="body1" align="center" sx={{ mt: 2 }}>
              {t('signUp:signInSpan')}{" "}
                <Link href="/sign-in" underline="hover">
                  {t('signUp:signInLink')}
                </Link>
              </Typography>
            </Box>

            <Box sx={{ my: 2 }}>
              <Divider>{t('signUp:googleSpan')}</Divider>
            </Box>

            <Box display="flex" justifyContent="center" >
              <GoogleOAuthProvider clientId="390500823014-ciifh0f6gq5bpk5uhg6m2i97bfdoqfiv.apps.googleusercontent.com">
                <GoogleLogin onSuccess={handleSuccess} onError={handleError} />
              </GoogleOAuthProvider>
            </Box>

          </Paper>
        </Box>
      </Box>
    </ThemeProvider>
  );
};

export default Registration;