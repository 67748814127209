import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import HttpApi from 'i18next-http-backend';  // Подключаем backend

i18n
    .use(HttpApi)
    .use(initReactI18next)
    .init({
        backend: {
            loadPath: 'locales/{{lng}}.json',
        },
        fallbackLng: 'en',
        debug: true,
        keySeparator: ':',
        interpolation: {
            escapeValue: false,
        },
        react: {
            useSuspense: false,
        },
    });

console.log('Done');
export default i18n;
