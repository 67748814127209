import React, { useEffect, useState } from "react";
import {
  Box,
  TextField,
  Button,
  Typography,
  Paper,
  Link,
  createTheme,
  ThemeProvider,
  Divider,
  Grid,
} from "@mui/material";
import { FaGoogle, FaApple, FaMicrosoft } from "react-icons/fa"; // Иконки из react-icons
import axiosInstance from "../../axiosInstace";
import { saveTokens } from "../../tokens";
import { useNavigate } from "react-router-dom";
import { GoogleLogin } from "@react-oauth/google";
import { GoogleOAuthProvider } from "@react-oauth/google";
import { useTranslation } from 'react-i18next'
import { getToken } from "../../tokens";

// Создаём кастомную тему
const theme = createTheme({
  palette: {
    primary: {
      main: "#B753F9", // Ваш основной цвет
    },
    secondary: {
      main: "#B753F9", // Ваш второстепенный цвет
    },
  },
});

const Login = () => {
  const { t, ready } = useTranslation();
  const navigate = useNavigate();
  const [errorMessage, setErrorMessage] = useState('');

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const handleLogin = async () => {
    window.umami.track("Login attempt");
    const response = await axiosInstance.post("/api/login", {
      email,
      password,
    });

    if (response.status === 409) {
      setErrorMessage('Неверный логин или пароль');
      window.umami.track("Wrong login or password");
    }

    if (response.status === 200) {
      saveTokens(response.data.accessToken, response.data.refreshToken, 20 * 60);
      window.umami.track("Success login");
      navigate("/");
    }
  };

  useEffect(() => {
    setErrorMessage('');
  }, [email, password]);

  useEffect(() => {
    window.umami.track("Email input in login");
  }, [email]);

  useEffect(() => {
    window.umami.track("Password input in login");
  }, [password]);

  useEffect(() => {
    const token = getToken();
    if (token && token !== null) {
      navigate("/");
    }
  }, []);

  const handleSuccess = async (credentialResponse) => {
    window.umami.track("Success google login in login");
    const token = credentialResponse.credential;
    const body = {
      token: token
    }
    const response = await axiosInstance.post("/api/login/google", body);
    saveTokens(response.data.accessToken, response.data.refreshToken, 20 * 60);
    navigate("/");
  };

  const handleError = () => {
    window.umami.track("Error google login in login");
  };

  return (
    <ThemeProvider theme={theme}>
      <Box
        display="flex"
        flexDirection="column"
        minHeight="100vh"
        bgcolor="#f5f5f5"
        sx={{
          backgroundImage: 'url("./background.svg")', // Путь к картинке
          backgroundSize: "cover", // Картинка будет растягиваться на весь экран
          backgroundPosition: "center", // Позиционирование картинки по центру
          backgroundRepeat: "no-repeat", // Без повторов картинки
        }}
      >
        <Box flexGrow={1} display="flex" justifyContent="center" alignItems="center">
          <Paper elevation={3} sx={{ p: 4, width: 400 }}>
            <Typography variant="h5" align="center" gutterBottom>
              {t('signIn:title')}
            </Typography>
            <Box component="form" noValidate autoComplete="off" sx={{ mt: 2 }}>
              <TextField
                label={t('signIn:inputs:email')}
                variant="outlined"
                fullWidth
                margin="normal"
                error={errorMessage !== ''}
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
              <TextField
                label={t('signIn:inputs:password')}
                type="password"
                variant="outlined"
                fullWidth
                margin="normal"
                error={errorMessage !== ''}
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
              <Button
                variant="contained"
                color="primary"
                fullWidth
                sx={{ mt: 2 }}
                onClick={handleLogin}
              >
                {t('signIn:signInButton')}
              </Button>
              <Typography sx={{ mt: 2, mb: 2 }} variant="body1" align="center" color="rgb(237, 73, 86)" >
                {errorMessage}
              </Typography>
              <Typography variant="body2" align="center" sx={{ mt: 2 }}>
                {t('signIn:signInSpan')}{" "}
                <Link href="/sign-up" underline="hover">
                  {t('signIn:signUpLink')}
                </Link>
              </Typography>
            </Box>

            <Box sx={{ my: 2 }}>
              <Divider>{t('signIn:googleSpan')}</Divider>
            </Box>

            <Box display="flex" justifyContent="center" >
              <GoogleOAuthProvider clientId="390500823014-ciifh0f6gq5bpk5uhg6m2i97bfdoqfiv.apps.googleusercontent.com">
                <GoogleLogin onSuccess={handleSuccess} onError={handleError} />
              </GoogleOAuthProvider>
            </Box>

          </Paper>
        </Box>
      </Box>
    </ThemeProvider>

  );
};

export default Login;