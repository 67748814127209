import logo from './logo.svg';
import './App.css';
import Login from './screens/login/login';
import ReactDOM from "react-dom/client";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Registration from './screens/login/registration';
import { useEffect } from 'react';
import { refreshToken } from './tokens';
import Home from './screens/home';
import Cookies from './components/cookies/cookies';
import Footer from './components/footer/footer';
import { useTranslation } from 'react-i18next'

function App() {
  useEffect(() => {
    const interval = setInterval(() => {
      refreshToken();
    }, 3000)

    return () => {
      clearInterval(interval);
    }
  }, [])

  const { t, ready } = useTranslation();
  if (!ready){
    return null;
  }

  return (
    <BrowserRouter>
      <Routes>
        <Route path="/sign-in" element={<Login />} />
        <Route path="/sign-up" element={<Registration />} />
        <Route path="/" element={<Home />} />
      </Routes>
      <Cookies />
      <Footer />
    </BrowserRouter>
  );
}

export default App;
