import "./style.css";
import { useTranslation } from 'react-i18next';
import { useEffect } from 'react';

const Footer = () => {
	const { t, i18n } = useTranslation();

	useEffect(() => {
		const lng = getPreferredLanguage();
		i18n.changeLanguage(lng);
	}, []);

	const handleLanguageChange = (e) => {
		i18n.changeLanguage(e.target.value);
		localStorage.setItem('preferredLanguage', e.target.value);
	};
	const getPreferredLanguage = () => {
		const storedLang = localStorage.getItem('preferredLanguage');
		if (storedLang) {
			return storedLang;
		}

		const browserLang = navigator.languages ? navigator.languages[0] : navigator.language;
		const normalizedLang = browserLang.toLowerCase().startsWith('ru') ? 'ru'
			: browserLang.toLowerCase().startsWith('uk') || browserLang.toLowerCase().startsWith('ua') ? 'ua'
				: 'en';
		return normalizedLang;
	};

	const privacyPolicyClick = () => {
		window.umami.track("privacy-policy-click");
	}
	return (
		<div className="footer-section section home-section" >
			<div className="container">
				<div className="row text-center">
					<div className="col-md-12">
						<ul className="footer-links inline-list" style={{padding:"10px"}}>
							<li> Copyright © 2024 Flinzy</li>
							<li>
								<a
									href="https://www.freeprivacypolicy.com/live/24d78cca-c312-4aa8-8106-fdc1a0eb6857"
									target="_blank"
									rel="noopener noreferrer"
									onClick={privacyPolicyClick}
								>
									{t('Privacy Policy')}
								</a>
							</li>
							<li className="localization-menu-wrapper" style={{ display: "flex" }}>
								<select
									name="languages"
									className="localization-menu"
									value={i18n.language} // Текущее значение языка
									onChange={handleLanguageChange}
								>
									<option value="en">EN</option>
									<option value="ru">RU</option>
									<option value="ua">UA</option>
								</select>
							</li>
						</ul>
					</div>
				</div>
			</div>
		</div>
	);
}

export default Footer;