import { useEffect, useState, useLayoutEffect } from "react";
import { getToken } from "../tokens";
import { useNavigate } from "react-router-dom";

const Home = () => {
    const navigate = useNavigate();

    const [scriptsLoaded, setScriptsLoaded] = useState(false);


    useEffect(() => {
        const token = getToken();
        if (!token || token === null) {
            navigate("/sign-in");        
        }
    }, []);

    const link = document.createElement('link');
    link.href = "assets/style.css?ver=131";
    link.rel = "stylesheet";
    link.type = "text/css";
    document.head.appendChild(link);

    const script1 = document.createElement('script');
    script1.src = '/assets/jquery.bundle.js';
    script1.async = true;
    script1.onload = checkScriptsLoaded;
    document.body.appendChild(script1);

    const script2 = document.createElement('script');
    script2.src = '/assets/script.js';
    script2.async = true;
    script2.onload = checkScriptsLoaded;
    document.body.appendChild(script2);


    const script3 = document.createElement('script');
    script3.src = 'https://code.jquery.com/jquery-3.6.0.min.js';
    script3.async = true;
    script3.onload = checkScriptsLoaded;
    document.body.appendChild(script3);

    const script4 = document.createElement('script');
    script4.src = 'https://cdnjs.cloudflare.com/ajax/libs/cookieconsent2/3.1.0/cookieconsent.min.js';
    script4.async = true;
    script4.onload = checkScriptsLoaded;
    document.body.appendChild(script4);

    let loadedScripts = 0;

    function checkScriptsLoaded() {
        loadedScripts += 1;
        if (loadedScripts === 4) {
            setScriptsLoaded(true);
        }
    }

    if (!scriptsLoaded) {
        return null;
    }

    return (
        <>
            <div>
                <div id="particles-js" className="particles-container particles-background" style={{ position: 'relative' }}>
                    <div className="coming-soon-wrapper">
                        <h1> Coming Soon!</h1>
                        <h2>Flinzy is under development</h2>
                    </div>

                </div>
            </div>
        </>
    );
};

export default Home;